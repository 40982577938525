<template>
  <div class="">
    <query-form v-model="queryForm" :query-but-loading="tableLoading" label-width="100px" @query="handleQuery" @reset="handleReset">
      <template>
        <el-col :span="8">
          <el-form-item label="STYLE">
            <SelectBasics v-model="queryForm.styleId" api-key="style" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="COLOR">
            <SelectBasics v-model="queryForm.colorId" api-key="color" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="SIZE">
            <SelectBasics v-model="queryForm.sizeId" api-key="size" />
          </el-form-item>
        </el-col>
      </template>
      <template v-slot:others>
        <el-col :span="8">
          <el-form-item label="FNSKU/UPC">
            <el-input v-model="queryForm.fnsku" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="SKU">
            <el-input v-model="queryForm.sku" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </template>
    </query-form>
    <el-row type="flex" justify="space-between">
      <el-col>
        <el-button @click="handleExport">
          {{ $t('page.export') }}
        </el-button>
        <el-button type="primary" @click="handleUpload">导入</el-button>
      </el-col>
      <el-row type="flex" justify="space-between">
        <el-button type="warning" @click="handleInventoryUpload">{{ $t('page.inventoryUpload') }}</el-button>
        <el-button type="primary" @click="handleSetAccountDisplay">{{ $t('page.setAccountDisplay') }}</el-button>
      </el-row>
    </el-row>
    <table-select-action :quantity="multipleSelection.length" @reset="handleTableSelectReset" />

    <el-table
      ref="queryTable"
      v-loading="tableLoading"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      show-summary
      :summary-method="getSummaries"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="60"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">{{ (pager.current - 1) * pager.size + scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column
        prop="sku"
        label="SKU"
        width="120"
      />
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        width="120"
      />
      <el-table-column
        prop="upc"
        label="UPC"
        width="120"
      />
      <el-table-column
        prop="style"
        header-align="center"
        align="center"
        :label="$t('page.Specifications')"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <template v-if="tableData[0] && tableData[0].platStockInfoVOS">
        <el-table-column
          v-for="(item, index) in tableData[0].platStockInfoVOS"
          :key="item.type"
          prop="platStockInfoVOS"
          :label="item.typeName + ''"
          width="120"
        >
          <template v-slot="scope">
            {{ scope.row.platStockInfoVOS[index].stockNum }}
          </template>
        </el-table-column>
      </template>
      <el-table-column
        label="总库存"
        width="120"
        prop="totalStock"
      />
      <template v-if="tableData[0] && tableData[0].accountStockInfoVOS">
        <el-table-column
          v-for="(item, index) in filterAccountList"
          :key="item.accountName"
          prop="accountStockInfoVOS"
          :label="item.accountName"
          width="120"
        >
          <template v-slot="{row}">
            <el-input v-model.number="row.accountStockInfoVOS[index].stockNum" placeholder="" />
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Paging :pager="pager" @update="pagerUpdate" />

    <el-dialog
      title="提示"
      :visible.sync="setAccountDisplayVisible"
      width="30%"
    >
      <el-checkbox-group v-model="checkList">
        <el-checkbox v-for=" item in allAccountList" :key="item.accountName" :label="item.accountName" />
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setAccountDisplayVisible = false">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="handleAccountSubmit">{{ $t('page.sure') }}</el-button>
      </span>
    </el-dialog>
    <Upload :visible="uploadVisible" @close="uploadVisible = false" />
  </div>
</template>

<script>
import { selectUploadRecordV2, listAllAccount, updateEbayAccount, uploadStock } from '@/api/omsorder'
import QueryForm from '@/components/QueryForm'
import Upload from './components/upload'
import Paging from '@/components/Paging'
import TableSelectAction from '@/components/TableSelectAction'
import SelectBasics from '@/components/SelectBasics'
import oms_base from '@/api/a-base-host'
import mixin from '@/mixin/oms-mixin'
export default {
  components: {
    QueryForm,
    Paging,
    TableSelectAction,
    SelectBasics,
    Upload
  },
  mixins: [mixin],
  data() {
    return {
      queryForm: {
        styleId: '',
        colorId: '',
        sizeId: '',
        fnsku: '',
        sku: ''
      },
      tableData: [],
      tableLoading: false,
      uploadVisible: false,
      setAccountDisplayVisible: false,
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      multipleSelection: [],
      checkList: [],
      allAccountList: []
    }
  },
  computed: {
    exportUrl() {
      const { styleId, colorId, sizeId, fnsku, sku } = this.queryForm
      return oms_base + `/oms/skuStockUpload/stockExport?styleId=${styleId}&colorId=${colorId}&sizeId=${sizeId}&fnsku=${fnsku}&sku=${sku}`
    },
    selectUploadRecordV2Params() {
      return Object.assign({}, this.queryForm, this.pager)
    },
    filterAccountList() {
      return this.allAccountList.filter(e => e.isChoose === 0)
    },
    updateEbayAccountParams() {
      const allAccountList = JSON.parse(JSON.stringify([...this.allAccountList]))
      const list = allAccountList.map(item => {
        this.checkList.some(s => s === item.accountName) ? item.isChoose = 0 : item.isChoose = 1
        return item
      })
      return Object.assign({}, { list })
    },
    uploadStockParams() {
      const list = []
      this.multipleSelection.forEach(item => {
        if (item.accountStockInfoVOS) {
          const accountList = item.accountStockInfoVOS.map(e => ({
            accountName: e.accountName,
            stockNum: !isNaN(e.stockNum) && typeof e.stockNum === 'number' ? e.stockNum : 0,
            sku: item.sku }))
          list.push(...accountList)
        }
      })
      return { list }
    }
  },
  watch: {
    'setAccountDisplayVisible'(val) {
      if (val) {
        this.checkList = this.allAccountList.filter(e => e.isChoose === 0).map(item => item.accountName)
      } else {
        this.checkList = []
      }
    }
  },
  mounted() {
    this._selectUploadRecordV2()
    this._listAllAccount()
  },
  methods: {
    handleQuery() {
      this.pager.current = 1
      this._selectUploadRecordV2()
    },
    handleUpload() {
      console.log(123)
      this.uploadVisible = true
    },
    handleReset() {
      this.queryForm = this.$options.data().queryForm
      this.pager.current = 1
      this._selectUploadRecordV2()
    },
    async _listAllAccount() {
      const { datas } = await listAllAccount()
      this.allAccountList = datas
    },
    pagerUpdate(val) {
      this.pager = val
      this._selectUploadRecordV2()
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      this.$refs.queryTable.clearSelection()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSetAccountDisplay() {
      this.setAccountDisplayVisible = true
    },
    handleExport() {
      window.open(this.exportUrl, '_blank')
    },
    handleInventoryUpload() {
      if (this.uploadStockParams.length === 0 || this.uploadStockParams.list.length === 0) {
        this.$message.error(this.$t('page.selectOneData'))
        return
      }
      this.$confirm(this.$t('page.ebayUploadTip'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        const { msg } = await uploadStock(this.uploadStockParams)
        this.$message.success(msg)
        this._selectUploadRecordV2()
      })
    },
    async handleAccountSubmit() {
      const { msg } = await updateEbayAccount(this.updateEbayAccountParams)
      this.$message.success(msg)
      this._selectUploadRecordV2()
      this.allAccountList = this.updateEbayAccountParams.list
      this.setAccountDisplayVisible = false
    },
    async _selectUploadRecordV2() {
      try {
        this.tableLoading = true
        const { datas: { pager, records }} = await selectUploadRecordV2(this.selectUploadRecordV2Params)
        this.tableData = records
        this.pager = pager
      } finally {
        this.tableLoading = false
      }
    },
    getSummaries({ columns, data }) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('page.total')
          return
        }
        if (column.property === 'accountStockInfoVOS') {
          sums[index] = data.reduce((prev, curr) => {
            const accountStockInfoVosItem = curr.accountStockInfoVOS
            const currentItem = accountStockInfoVosItem.find(f => f.accountName === column.label)
            return prev + +currentItem.stockNum
          }, 0)
        }
        if (column.property === 'totalStock') {
          sums[index] = data.reduce((prev, curr) => prev + +curr.totalStock, 0)
        }
        if (column.property === 'platStockInfoVOS') {
          sums[index] = data.reduce((prev, curr) => {
            const platStockInfoVosItem = curr.platStockInfoVOS
            // eslint-disable-next-line
            const currentItem = platStockInfoVosItem.find(f => f.typeName == column.label)
            return prev + +currentItem.stockNum
          }, 0)
        }
      })
      return sums
    }
  }
}
</script>

<style>

</style>
