<template>
  <el-select
    :value="value"
    filterable
    :placeholder="$t('page.selectPlaceholder')"
    :disabled="disabled"
    :filter-method="filterMethod"
    @input="change($event)"
  >
    <el-option
      v-for="item in options"
      :key="item[elOptionConfiguration.key]"
      :label="item[elOptionConfiguration.label]"
      :value="item[elOptionConfiguration.value]"
    />
  </el-select>
</template>

<script>

import apiMapList from './apiMapList'
export default {
  name: 'SelectBasics',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean
    },
    emitValue: {
      type: String,
      default: 'id'
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    apiKey: {
      type: String,
      default: ''
    },
    configuration: {
      type: Object,
      default: () => ({})
    },
    findLength: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      options: [],
      requestData: [],
      selectConfiguration: {}
    }
  },
  computed: {
    elOptionConfiguration() {
      return Object.keys(this.configuration).length !== 0 ? this.configuration : this.selectConfiguration
    }
  },
  mounted() {
    const { api, configuration } = apiMapList.find(item => item.key === this.apiKey)
    this.selectConfiguration = configuration
    this._queryOptions(api)
  },
  methods: {
    filterMethod(query) {
      if (query === '') {
        this.options = this.requestData.slice(0, this.findLength)
      } else {
        const result = []
        const queryUppercase = query.toLocaleUpperCase()
        this.requestData.forEach(item => {
          const locationCodeUppercase = item[this.elOptionConfiguration.label].toLocaleUpperCase()
          if (locationCodeUppercase.includes(queryUppercase)) result.push(item)
        })
        this.options = result.slice(0, this.findLength)
      }
    },
    change(val) {
      this.$emit('input', val)
    },
    async _queryOptions(api) {
      if (this.selectOptions.length || !api) {
        this.requestData = this.selectOptions
        this.options = this.requestData.slice(0, this.findLength)
      } else {
        const { datas } = await api()
        this.requestData = datas
        this.options = this.requestData.slice(0, this.findLength)
      }
    }
  }
}
</script>
