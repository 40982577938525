import { queryStyleList, querySizeList, queryColorList } from '@/api/select'

export default [
  {
    key: 'style',
    api: queryStyleList,
    configuration: {
      key: 'id',
      label: 'styleName',
      value: 'id'
    }
  },
  {
    key: 'size',
    api: querySizeList,
    configuration: {
      key: 'id',
      label: 'size',
      value: 'id'
    }
  },
  { key: 'color',
    api: queryColorList,
    configuration: {
      key: 'id',
      label: 'colorName',
      value: 'id'
    }}
]
