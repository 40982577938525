<template>
  <div class="query-form">
    <div class="query-form--item">
      <el-form ref="queryForm" :value="value" :label-width="labelWidth" @input="input(val)">
        <el-row>
          <slot />
        </el-row>
        <el-row v-show="OthersIsShow">
          <slot name="others" />
        </el-row>
      </el-form>
    </div>
    <div class="query-form--operation ml-2">
      <el-button type="primary" :loading="queryButLoading" @click="handleQuery">{{ $t('page.search') }}</el-button>
      <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
      <el-button v-show="expandVisible" type="text" @click="OthersIsShow = !OthersIsShow">{{ OthersIsShow ? $t('page.hide') : $t('page.expand') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    queryButLoading: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: '120px'
    },
    expandVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      OthersIsShow: false
    }
  },
  methods: {
    handleQuery() {
      this.$emit('query')
    },
    handleReset() {
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
.query-form {
  display: flex;
  .query-form--item {
    flex: 8;
  }
  .query-form--operation {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: row;
  }
}
</style>
