<template>
  <div class="stock-import">
    <el-dialog :title="$t('page.UploadAndImportFiles')" center width="40%" :visible.sync="outerVisible">
      <el-dialog
        class="inner-dialog"
        width="80%"
        :title="$t('page.TheImportDetails')"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-button-group class="mb-2">
          <el-button type="primary" @click="handleSelectError">勾选所有异常数据</el-button>
          <el-button type="primary" @click="handleDeletedSelectList">{{ $t('page.delete') }}</el-button>
        </el-button-group>
        <vxe-table
          v-if="innerVisible"
          ref="vxeTable"
          class="vxeTable"
          size="mini"
          border
          show-overflow
          highlight-hover-row
          height="600"
          :row-class-name="errorClass"
          :data="tableData"
        >
          <vxe-table-column type="checkbox" width="50" />
          <vxe-table-column field="sku" width="300" title="SKU" />
          <vxe-table-column field="fnsku" width="100" title="FNSKU" />
          <vxe-table-column field="upc" width="100" title="UPC" />
          <vxe-table-column field="style" :title="$t('page.Specifications')" width="250">
            <template v-slot="{ row }">{{ row.style }}/{{ row.color }}/{{ row.size }}</template>
          </vxe-table-column>
          <vxe-table-column
            title="总库存"
            width="120"
            field="totalStock"
          />
          <template v-if="tableData[0] && tableData[0].accountStockInfoVOS">
            <vxe-table-column
              v-for="(item,index) in tableData[0].accountStockInfoVOS"
              :key="item.accountName"
              :title="item.accountName"
              width="120"
            >
              <template v-slot="{row}">
                {{ row.accountStockInfoVOS[index].stockNum }}
              </template>
            </vxe-table-column>
          </template>
          <vxe-table-column field="errorMsg" title="错误信息" />
        </vxe-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">{{ $t('title.cancel') }}</el-button>
          <el-button :loading="submitLoading" type="primary" :disabled="importDisabled" @click="handleSubmit">{{ $t('page.import') }}</el-button>
        </div>
      </el-dialog>
      <el-upload
        ref="upload"
        accept=".xlsx,.xls"
        class="upload-file"
        :before-upload="beforeUpload"
        :limit="1"
        drag
        :on-error="handleError"
        :on-remove="handleRemove"
        :headers="uploadHeaders"
        :on-success="handleSuccess"
        :action="action"
      >
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">只能上传.xls与.xlsx文件，且不超过1MB</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" :disabled="importDisabled" @click="innerVisible = true">下一步</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const oms_base = process.env.NODE_ENV === 'production' ? 'http://oms.wangoon.cn' : 'http://oms-ngrok.wangoon.cn'
import { uploadStock } from '@/api/omsorder'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerVisible: false,
      submitLoading: false,
      tableData: [],
      initTableData: [],
      columnArray: [],
      action: oms_base + '/oms/skuStockUpload/stockImport'
    }
  },
  computed: {
    uploadHeaders() {
      return {
        authorization: this.$store.getters.authorization,
        'x-userinfo-header': `${this.$store.getters.tenantId}-${this.$store.getters.userId}-${this.$store.getters.name}-${this.$store.getters.warehouse || 1}`
      }
    },
    importDisabled() {
      return !this.tableData.length
    },
    uploadStockParams() {
      const list = []
      this.tableData.forEach(item => {
        if (item.accountStockInfoVOS) {
          const accountList = item.accountStockInfoVOS.map(e => ({
            accountName: e.accountName,
            stockNum: !isNaN(e.stockNum) && typeof e.stockNum === 'number' ? e.stockNum : 0,
            sku: item.sku }))
          list.push(...accountList)
        }
      })
      return { list }
    },
    outerVisible: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          Object.assign(this.$data, this.$options.data())
          this.$refs['upload'].clearFiles()
          this.$emit('close')
        }
      }
    }
  },
  watch: {
    'innerVisible'(val) {
      if (!val) {
        this.$nextTick(() => {
          this.tableData = this.initTableData
        })
      }
    }
  },
  methods: {
    handleSelectAll() {
      const selectList = this.tableData.filter(item => item.error)
      this.$refs.vxeTable.setCheckboxRow(selectList, true)
    },
    handleDeletedError() {
      this.tableData = this.tableData.filter(item => item.uploadType === 0)
    },
    handleSelectError() {
      const selectList = this.tableData.filter(item => item.uploadType === 1)
      this.$refs.vxeTable.setCheckboxRow(selectList, true)
    },
    handleRemove() {
      Object.assign(this.$data, this.$options.data())
    },
    handleDeletedSelectList() {
      const selectRecords = this.$refs.vxeTable.getCheckboxRecords()
      const _XIDS = selectRecords.map(item => item._XID)
      this.tableData = this.tableData.filter(item => !_XIDS.includes(item._XID))
    },
    handleSuccess(response, file, fileList) {
      this.tableData = response
      this.initTableData = response
      this.innerVisible = true
    },
    beforeUpload(file) {
      const fileArr = file.name.split('.')
      const isExcel = fileArr[fileArr.length - 1] === 'xlsx' || fileArr[fileArr.length - 1] === 'xls'
      const isSize = file.size / 1024 / 1024 < 1
      if (!isExcel) {
        this.$message.error(this.$t('page.OnlyXlsAndXlsxFilesCanBeUploaded'))
      }
      if (!isSize) {
        this.$message.error(this.$t('page.UploadFileSizeShouldNotExceed'))
      }
      return isExcel && isSize
    },
    async handleSubmit() {
      const errorList = this.tableData.filter(item => item.uploadType === 1)
      if (errorList.length !== 0) {
        this.$message.error('请删除错误数据')
        return
      }
      this.$confirm(this.$t('page.ebayUploadTip'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        try {
          this.submitLoading = true
          const { msg } = await uploadStock(this.uploadStockParams)
          this.$message.success(msg)
          this.outerVisible = false
          this.innerVisible = false
        } finally {
          this.submitLoading = false
        }
      })
    },
    errorClass({ row }) {
      if (row.uploadType === 1) {
        return 'error-row'
      } else {
        return ''
      }
    },
    handleError(err, file, fileList) {
      this.$message.error(JSON.parse(err.message))
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-file  /deep/ .el-upload , .upload-file /deep/ .el-upload-dragger {
  width: 100%;
}
.inner-dialog >>> .el-dialog__body {
  padding-top: 0;
}
.vxeTable /deep/ .error-row {
  background: #F44336;
  color: #fff;
}
</style>
<style>
.vxe-table--tooltip-wrapper.theme--dark {
    z-index: 999999 !important;
}
</style>
